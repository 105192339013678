import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { ProductChainType } from './useProductChain'
import { Ref, computed } from 'vue'
import { ProductEngravingType } from './useProductEngraving'
import { ProductSelectionType } from './useProductSelection'

type ProductPriceProps = {
  product: ProductFieldsFragment
  selection: ProductSelectionType
  chain: ProductChainType
  insideEngraving: ProductEngravingType
  outsideEngraving: ProductEngravingType
}

const mapEdges = (object: any) => {
  return object.edges.map((edge: any) => edge.node)
}

export const useProductPrice = ({
  product,
  selection,
  chain,
  insideEngraving,
  outsideEngraving,
}: ProductPriceProps) => {
  const priceMax: Ref<number> = computed(() => {
    const chainProduct = chain.chainProduct

    const maxPrice = parseFloat(product?.priceRange?.maxVariantPrice.amount)

    if (maxPrice && chainProduct?.value) {
      const chainMaxPrice = parseFloat(chainProduct.value?.priceRange?.maxVariantPrice.amount)

      return maxPrice + chainMaxPrice
    }

    return maxPrice
  })

  const priceMin: Ref<number> = computed(() => {
    const minVariantPrice = mapEdges(product.variants).reduce((c, v) => {
      const price = parseFloat(v.price.amount)
      if (price > 0 && price < c) return price
      return c
    }, 9999)
    // return parseFloat(product.priceRange?.minVariantPrice.amount || '9999')
    return minVariantPrice
  })

  const priceCompare: Ref<number | undefined> = computed(() => {
    const priceCompare = parseFloat(product?.compareAtPriceRange?.maxVariantPrice.amount || '0')
    if (priceCompare > 0 && priceCompare > priceMin.value) return priceCompare
    return undefined
  })

  const hasPriceRange = computed(() => {
    return priceMin.value < priceMax.value
  })

  // Construct total selection price
  const priceTotal = computed(() => {
    const variantPrice = Number(parseFloat(selection.selectedVariant.value?.price.amount) || 0)

    const chainPrice = Number(chain.isActive.value && chain.selection.value.selectedAmount) || 0

    const insideEngravingPrice =
      Number(insideEngraving.isActive.value && insideEngraving.selection.value.selectedAmount) || 0

    const outsideEngravingPrice =
      Number(outsideEngraving.isActive.value && outsideEngraving.selection.value.selectedAmount) || 0

    return insideEngravingPrice + outsideEngravingPrice + chainPrice + variantPrice
  })

  return { priceMin, priceMax, priceCompare, hasPriceRange, priceTotal }
}
