<template>
  <transition name="fade">
    <ProductProvider v-if="product && shouldShow" :product="product">
      <ProductShow />
      <ProductInfluences />
      <ProductOverlayQuestion />
      <ProductOverlayGallery />
    </ProductProvider>
  </transition>
  <!-- <div v-else>Product not found</div> -->

  <!-- <OverlayLimit /> -->
  <!-- <OverlayInfo /> -->
</template>

<script lang="ts" setup>
import { Ref, ref, computed } from 'vue'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { DEFAULT_COUNTRY_CODE, fetchProductByHandle } from '_library'

import ProductShow from '../product/ProductShow.vue'
import ProductProvider from '../product/ProductProvider.vue'
import ProductInfluences from '../product/ProductInfluences.vue'
import ProductOverlayQuestion from '../product/ProductOverlayQuestion.vue'
import ProductOverlayGallery from '../product/ProductOverlayGallery.vue'

const props = defineProps<{
  handle: string
}>()

const product: Ref<ProductFieldsFragment | null> = ref(null)
const isDraft = (product: ProductFieldsFragment) => product.tags.find((tag) => tag == 'draft')
const isPreview = !!new URLSearchParams(window.location.search).get('preview')

const shouldShow = computed(
  () => !!product.value && (!isDraft(product.value) || (isDraft(product.value) && isPreview))
)

const fetchProduct = async () => {
  const fetched = await fetchProductByHandle(props.handle, { countryCode: DEFAULT_COUNTRY_CODE })
  if (fetched) product.value = fetched
}

fetchProduct()
</script>

<style lang="scss"></style>
