import { RemoveLineItemProps, RemoveLineItemsProps } from '_library/src/ajax/cart'
import {
  fetchCart as _fetchCart,
  addLineItem as _addLineItem,
  addLineItems as _addLineItems,
  updateLineItem as _updateLineItem,
  removeLineItem as _removeLineItem,
  removeLineItems as _removeLineItems,
  updateCart as _updateCart,
  AddLineItemProps,
  UpdateLineItemProps,
  UpdateCartProps,
} from '_library'
import { Ref, provide, inject, ref, computed, onMounted } from 'vue'
import { LiquidCart } from '../types/liquid'

type CartStatusType = '' | 'ADDING' | 'ADDED' | 'REMOVING' | 'REMOVED'

export type CartContextType = {
  cart: Ref<LiquidCart>
  cartStatus: Ref<CartStatusType>
  hasItems: Ref<boolean>
  itemCount: Ref<number>

  fetchCart: any
  updateCart: (_: UpdateCartProps) => any
  addLineItem: (_: AddLineItemProps) => any
  addLineItems: (_: AddLineItemProps[]) => any
  updateLineItem: (_: UpdateLineItemProps) => any
  removeLineItem: (_: RemoveLineItemProps) => any
  removeLineItems: (_: RemoveLineItemsProps) => any
}

export const CART_INJECTION_KEY = Symbol('CART')

export const useCartContext = (initialCart: LiquidCart) => {
  const cart: Ref<LiquidCart> = ref(initialCart)
  const cartStatus: Ref<CartStatusType> = ref('')
  const hasItems: CartContextType['hasItems'] = computed(() => cart.value?.items?.length > 0)
  const itemCount: CartContextType['itemCount'] = computed(() => cart.value?.items?.length || 0)

  const beforeCartStatus = (status: CartStatusType) => {
    cartStatus.value = status
  }

  const afterCartStatus = (status: CartStatusType) => {
    cartStatus.value = status
    setTimeout(() => (cartStatus.value = ''), 2000)
  }

  const fetchCart = async () => {
    cart.value = await _fetchCart()
  }

  const addLineItems = async (props: AddLineItemProps[]) => {
    beforeCartStatus('ADDING')
    await _addLineItems(props)
    afterCartStatus('ADDED')
    // await wait(dd200)
    await fetchCart()
  }

  const addLineItem = async (props: AddLineItemProps) => {
    beforeCartStatus('ADDING')
    await _addLineItem(props)
    await fetchCart()
    afterCartStatus('ADDED')
  }

  const updateLineItem = async (props: UpdateLineItemProps) => {
    await _updateLineItem(props)
    await fetchCart()
  }

  const removeLineItem = async (props: RemoveLineItemProps) => {
    beforeCartStatus('REMOVING')
    await _removeLineItem(props)
    await fetchCart()
    afterCartStatus('REMOVED')
  }

  const removeLineItems = async (props: RemoveLineItemsProps) => {
    beforeCartStatus('REMOVING')
    await _removeLineItems(props)
    await fetchCart()
    afterCartStatus('REMOVED')
  }

  const updateCart = async (props: UpdateCartProps) => {
    await _updateCart(props)
    await fetchCart()
  }

  // Initialise the cart
  onMounted(() => {
    fetchCart()
  })

  provide<CartContextType>(CART_INJECTION_KEY, {
    cart,
    cartStatus,
    hasItems,
    itemCount,
    fetchCart,
    updateCart,
    addLineItem,
    addLineItems,
    updateLineItem,
    removeLineItem,
    removeLineItems,
  })
}

export const useCartInject = () => {
  const context = inject<CartContextType>(CART_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
