<template>
  <FormAddress @onSubmit="onSubmit" />
</template>

<script lang="ts" setup>
import { submitAddressUpdate } from '../../services/shopify/account'
import FormAddress from './FormAddress.vue'

const onSubmit = async (payload: Event) => {
  const formData = new FormData(payload.target as HTMLFormElement)

  const params = {
    'address[first_name]': formData.get('firstName'),
    'address[last_name]': formData.get('lastName'),
    'address[company]': formData.get('company'),
    'address[address1]': formData.get('address1'),
    'address[address2]': formData.get('address2'),
    'address[country]': formData.get('country'),
    'address[province]': formData.get('province'),
    'address[city]': formData.get('city'),
    'address[phone]': formData.get('phone'),
    'address[zip]': formData.get('zip'),
  }

  await submitAddressUpdate(params)
}
</script>

<style></style>
