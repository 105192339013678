<template>
  <div class="product-accordion">
    <ProductAccordionItem title="Details" class="product-accordion__item">
      <div v-html="content.description[0]"></div>
    </ProductAccordionItem>

    <ProductAccordionItem
      v-for="(desc, descIndex) in content.descriptions"
      :title="desc.heading"
      :key="`desc_${descIndex}`"
      class="product-accordion__item"
    >
      <div v-html="desc.body"></div>
    </ProductAccordionItem>

    <ProductAccordionItem
      v-if="content.polishFreeFrom"
      title="Free From"
      class="product-accordion__item"
    >
      <div v-html="content.polishFreeFrom"></div>
    </ProductAccordionItem>

    <ProductAccordionItem
      v-if="content.polishIngredients"
      title="Ingredients"
      class="product-accordion__item"
    >
      <div v-html="content.polishIngredients"></div>
    </ProductAccordionItem>

    <ProductAccordionItem v-if="!isNoSale" title="Shipping & Returns" class="product-accordion__item">
      <div v-html="content.customShippingInfo" v-if="content.customShippingInfo"></div>
      <div v-html="content.polishShippingInfo" v-else-if="content.polishShippingInfo"></div>
      <div v-html="content.defaultShippingInfo" v-else></div>
    </ProductAccordionItem>

    <!-- <button class="influences-button" v-if="hasInfluences" @click.prevent="scrollToInfluences">
      See Influences <SvgArrowDown />
    </button> -->
  </div>
</template>

<script lang="ts" setup>
// import SvgArrowDown from '../svg/SvgArrowDown.vue'
import ProductAccordionItem from './ProductAccordionItem.vue'
import { useProductInject } from '../../context/useProductContext'
import { useAccordionContext } from '../../context/useAccordionContext'

useAccordionContext({
  initial: 'Details',
})

const { content, isNoSale } = useProductInject()
</script>

<style lang="scss">
.product-accordion {
  &__item {
    padding-bottom: 20px;
  }
}
</style>
