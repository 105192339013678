<template>
  <button class="product-option-value" @click.prevent="onClick" :class="classes">
    <span class="product-option-value__text">{{ value }}</span>
  </button>
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'
import {
  ProductFieldsFragment,
  ProductOptionFieldsFragment,
  VariantFieldsFragment,
} from '_library/src/storefront/generated'
import { findSelectedVariant, mapGraph, mergeProductOptions } from '_library'
import { ProductSelectionType } from '../../composables/useProductSelection'

const props = defineProps<{
  product: ProductFieldsFragment
  selection: ProductSelectionType
  option: ProductOptionFieldsFragment
  value: string
}>()

const variants = mapGraph(props.product.variants) as VariantFieldsFragment[]

const selectedOptions = computed(() => unref(props.selection.selectedOptions))
const selectedOption = computed(() =>
  selectedOptions.value?.find((option) => option.name == props.option.name)
)

const variant = computed(() =>
  findSelectedVariant(
    variants,
    mergeProductOptions(selectedOptions.value, `${props.option?.name}`, `${props.value}`)
  )
)

const classes = computed(() => [
  `product-option-value--${selectedOption.value?.value == props.value ? 'active' : 'inactive'}`,
  `product-option-value--${variant.value?.availableForSale ? 'available' : 'unavailable'}`,
  `product-option-value--${
    variant.value?.availableForSale && variant.value?.quantityAvailable > 0
      ? 'in-stock'
      : 'made-to-order'
  }`,
])

const onClick = () => {
  if (variant.value) props.selection.selectVariant(variant.value)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-option-value {
  &--inactive {
    .product-option-value {
      &__text {
        color: var(--color-midgrey);
      }
    }
  }

  &--unavailable {
    pointer-events: none;
    .product-option-value {
      &__text {
        text-decoration: line-through;
      }
    }
  }

  &--in-stock {
    .product-option-value {
      &__text {
        &:after {
          content: '*';
        }
      }
    }
  }

  @include breakpoint('l') {
    border: 1px solid #b3b3b3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 7ch;
    height: 35px;
    padding: 1px 10px 0;
    transition: border-color 0.2s ease;
  }

  &:hover:not(.product-option-value--unavailable),
  &--active {
    @include breakpoint('l') {
      border-color: var(--color-dark);
    }

    .product-option-value {
      &__text {
        @include breakpoint('l+') {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
