<template>
  <AccountDashboard title="Account Addresses">
    <section class="page-account-addresses">
      <div class="page-account-addresses__list">
        <h3 class="page-account-addresses__subtitle">Address List</h3>
        <AccountAddress
          v-for="address in customer.addresses"
          :key="address.id"
          :address="address"
          :is-default="address.id == customer?.default_address?.id"
          class="page-account-addresses__address"
        >
          <div class="page-account-addresses__controls">
            <button class="page-account-addresses__control" @click.prevent="() => setAddress(address)">
              Edit
            </button>
            |
            <button
              @click.prevent="() => deleteAddress(address)"
              class="page-account-addresses__control"
            >
              Delete
            </button>
          </div>
        </AccountAddress>
      </div>
      <div class="page-account-addresses__form">
        <h2 class="page-account-addresses__subtitle">{{ mode }} Address</h2>
        <FormAddressEdit @onCancel="onCancel" v-if="currentAddress?.id" :address="currentAddress" />
        <FormAddressCreate v-else />
      </div>
    </section>
  </AccountDashboard>
</template>

<script lang="ts" setup>
import { inject, provide, ref } from 'vue'
import { LiquidAddress, LiquidCustomer } from '../../types/liquid'
import { parseCountryOptions } from '../../utils/shopify'

import AccountDashboard from '../account/AccountDashboard.vue'
import AccountAddress from '../account/AccountAddress.vue'
import FormAddressEdit from '../form/FormAddressEdit.vue'
import FormAddressCreate from '../form/FormAddressCreate.vue'
import { submitAddressDelete } from '../../services/shopify/account'

const props = defineProps<{ customer: LiquidCustomer; provinces: string }>()

const countries = parseCountryOptions(props.provinces)
provide('countries', countries)

type Mode = 'Create' | 'Edit'
const mode = ref<Mode>('Create')
const currentAddress = ref<LiquidAddress | null>(null)

const setAddress = (address: LiquidAddress) => {
  currentAddress.value = address
  mode.value = 'Edit'
}

const deleteAddress = async (address: LiquidAddress) => {
  await submitAddressDelete({ id: address.id })
}

const onCancel = () => {
  currentAddress.value = null
  mode.value = 'Create'
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.page-account-addresses {
  width: columns(12);
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;

  &__address {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__edit {
    text-decoration: underline;
  }

  &__subtitle {
    @include uppercase;
    border-bottom: 1px solid var(--color-dark);
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &__controls {
    padding-top: 20px;
  }

  &__control {
    text-decoration: underline;
  }
}
</style>
